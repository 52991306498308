import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/login/Login')
  },
  {
    path: '/basicSearch',
    name: 'basicSearch',
    meta: {
      mainTitle: '线索宝',
      selfTitle: '线索宝'
    },
    component: () => import('../components/data/basicSearch')
  },
  {
    path: '/advanceSearch',
    name: 'advanceSearch',
    meta: {
      mainTitle: '高级搜索',
      selfTitle: '高级搜索'
    },
    component: () => import('../components/data/advanceSearch'),
  },{
    path: '/loading',
    name: 'loading',
    component: () => import('../components/data/loadingSearch'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
