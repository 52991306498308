import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appOffsetWidth: 0,
    appOffsetHeight: 0
  },
  getters: {
  },
  mutations: {
    SET_APP_OFFSET_HEIGHT: (state, appOffsetHeight) => {
      state.appOffsetHeight = appOffsetHeight;
    },
    SET_APP_OFFSET_WIDTH: (state, appOffsetWidth) => {
      state.appOffsetWidth = appOffsetWidth;
    }
  },
  actions: {
  },
  modules: {
  }
})
