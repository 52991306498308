import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import 'view-design/dist/styles/iview.css'

Vue.config.productionTip = false

Vue.use(Antd);
Vue.prototype.$message = message;

router.beforeEach((to, from, next) => {
  if (!from.name && !to.name) {
    next('/');
    return;
  }

  if(!["login", "loading"].includes(to.name)){
    let userToken = sessionStorage.getItem('dataToken');
    if (!userToken) {
      next('/');
    }else{
      next()
    }
  }else{
    next()
  }
})

import {
  Table
} from 'view-design'

Vue.component('Table', Table);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
