<template>
  <div id="app" ref="app">
    <router-view/>
  </div>
</template>
<script>
 export default {
   created() {
     this.$nextTick(() => {
       this.$store.commit('SET_APP_OFFSET_HEIGHT', this.$refs.app.offsetHeight);
       this.$store.commit('SET_APP_OFFSET_WIDTH', this.$refs.app.offsetWidth);
     })
   },
   mounted() {
     window.onresize = () => {
       this.$store.commit('SET_APP_OFFSET_HEIGHT', this.$refs.app.offsetHeight);
       this.$store.commit('SET_APP_OFFSET_WIDTH', this.$refs.app.offsetWidth);
     }
   }
 }
</script>
<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
  //.ant-card-body
  //  box-shadow 0 1px 6px rgba(0,0,0,.2)
</style>
